
  import { defineComponent, onMounted, ref, computed } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  const selectedGov = ref<number>(0);

  export default defineComponent({
    name: 'areas-creating',
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const areaSchema = Yup.object().shape({
        titleEn: Yup.string()
          .required(() => translate('EN_NAME_IS_REQUIRED_FIELD'))
          .label('English Name'),
        titleAr: Yup.string()
          .required(() => translate('AR_NAME_IS_REQUIRED_FIELD'))
          .label('Arabic Name'),
      });

      const onSubmitCreate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        values.isRegisteredTrades =
          values.isRegisteredTrades == true ? true : false;
        values.isAd = values.isAd == true ? true : false;
        values.isAuction = values.isAuction == true ? true : false;

        await store.dispatch(Actions.CREATE_AREA, values);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_AREA'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'areas-listing' });
          });
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('areasCreating'), [
          translate('assets'),
        ]);
      });
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');
      await store.dispatch(Actions.GET_ALL_GOV_LIST);

      return {
        selectedGov,
        govs: computed(() => store.getters.allListGov),
        onSubmitCreate,
        submitButton,
        translate,
        areaSchema,
        goBack,
        can,
      };
    },
  });
